import React from "react"
import { AppProvider } from "./src/contexts"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/outfit/400.css"
import "@fontsource/outfit/500.css"
import "@fontsource/outfit/700.css"
import "@fontsource/outfit/800.css"
import "./src/assets/scss/main.scss"
export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      {element}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${
            process.env.GTM_ID || "GTM-PHR5M4C"
          }`}
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </AppProvider>
  )
}
